<template>
    <div class="info">
      <top-bar :title="'修改小事'" :left="true"></top-bar>

      <van-popup v-model="categoryshow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.categoryList"  value-key="label"  @confirm="categoryConfirm"  @cancel="categoryshow = false"/>
      </van-popup>

      <van-popup v-model="CommunityShow"  position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
      </van-popup>

      <van-popup v-model="positionShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.positionList" value-key="label"  @confirm="positionConfirm"  @cancel="positionShow = false"/>
      </van-popup>

      <van-popup v-model="roomUseShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.roomUseList" value-key="label"  @confirm="roomUseConfirm"  @cancel="roomUseShow = false"/>
      </van-popup>

      <van-popup v-model="gridShow" position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.gridList" value-key="label" @confirm="gridConfirm" @cancel="gridShow = false"  />
      </van-popup>

      <van-popup v-model="statusShow" position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.statusList" value-key="label" @confirm="statusConfirm" @cancel="statusShow = false"  />
      </van-popup>

      <van-popup v-model="BuildingShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到楼幢信息,请先选择小区或切换小区</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.BuildingList" value-key="label"  @confirm="BuildingConfirm"  @cancel="BuildingShow = false"/>
      </van-popup>

      <van-popup v-model="unitShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.unitList.length < 1 ">没有获取到单元信息,请先选择楼幢或切换楼幢</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.unitList" value-key="label"  @confirm="unitConfirm"  @cancel="unitShow = false"/>
      </van-popup>

      <van-popup v-model="houseShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.houseList.length < 1 ">没有获取到房屋信息,请先选择单元或切换单元</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.houseList" value-key="label"  @confirm="houseConfirm"  @cancel="houseShow = false"/>
      </van-popup>
      <van-cell-group>
        <van-cell   >
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">事件信息</span>
          </template>
        </van-cell>
        <van-cell title="所在网格"  :value="selectMsg.gridIdValue" @click="gridShow = true"/>
        <van-cell title="事件类别" :value="selectMsg.category"/>
        <van-cell title="所在小区" :value="selectMsg.Community"/>
        <van-cell title="所在位置" :value="selectMsg.position"/>
        <van-cell title="驾驶舱选项" :value="selectMsg.driveStatusValue"/>
<!--        <van-cell title="问题类别" :value="selectMsg.category"/>-->
        <van-cell title="所在楼幢" :value="selectMsg.Building" v-if="selectMsg.position == '室内楼幢'"/>
        <van-cell title="所在单元" :value="selectMsg.unit" v-if="selectMsg.position == '室内楼幢' && selectData.unitList.length >= 1"/>
        <van-cell title="所在房屋" :value="selectMsg.house" v-if="selectMsg.position == '室内楼幢' && selectData.houseList.length >= 1"/>
        <van-cell v-if="selectMsg.position == '室内楼幢'"><van-field v-model="selectMsg.room" label="房间名称" placeholder="请输入" style="text-align: right;"/></van-cell>
        <van-cell title="房间用途" :value="selectMsg.roomUse" v-if="selectMsg.position == '室内楼幢'"/>
        <van-cell title="事件描述">
          <van-field
            v-model="selectMsg.description"
            rows="4"
            autosize
            type="textarea"
            placeholder="这是一条事件描述"
            style="text-align: right;"
            disabled
          />
        </van-cell>
        <van-row class="uploadBox">
          <van-col :span="24">
            <van-uploader v-model="fileList" :deletable="false" disabled  :show-upload="false"/>
          </van-col>
        </van-row>
      </van-cell-group>
      <van-row class="btns">
        <van-col :span="24" style="padding-top: 80px">
          <van-button type="info" size="large" round @click="submit">完成</van-button>
        </van-col>
      </van-row>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getbelongSubArea, getDictTree} from '@/utils/common'
import {getImageStream} from '@/utils/index'

// import { mapState } from 'vuex'
export default {
  data () {
    return {
      file:{},
      detailData: {},
      categoryshow: false,
      CommunityShow: false,
      positionShow: false,
      BuildingShow: false,
      unitShow: false,
      houseShow: false,
      roomUseShow: false,
      gridShow: false,
      statusShow: false,
      selectMsg: {
        category: '请选择',
        Community: '请选择',
        position: '请选择',
        Building: '请选择',
        house: '请选择',
        unit: '请选择',
        room: '',
        roomUse: '请选择',
        description: '',
        gridIdValue: '请选择',
        driveStatusValue: '请选择'
      },
      submitData: {
        building:'',
        content:'',
        deleteFiles: [],
        fileMessages:[],
        newFileMessages: [],
        house:'',
        location:'',
        id: '',
        orgId:0,
        subarea:'',
        type:'',
        unit:'',
        roomUse: '',
        userId:'',
        gridId: '',
        driveStatus: ''
      },
      selectData: {
        categoryList: [],
        CommunityList: [],
        roomUseList:[{value:'1',label:'房东自住'},{value:'2',label:'出租'},{value:'3',label:'经营户'}],
        positionList: [{ label: "公共区域", value: "1" }, { label: "室内楼幢", value: "2" }, { label: "外部区域", value: "100" }],//, { label: "高层建筑", value: "2" }
        BuildingList: [],
        unitList: [],
        houseList: [],
        gridList: [],
        statusList: [{value: 0,label:'显示'},{value: 1,label:'不显示'}]
      },
      fileList: [],
      communityId: '',
    }
  },
  // computed:{...mapState(['problemData'])},
  components :{
    topBar
  },
  methods: {
    // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.submitData.newFileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", 'files/problem/temporary');
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.submitData.newFileMessages.push(data.fileMessage)
            return false
          } else {
            this.$toast.fail(data.msg);
          }
        })
      })
    },
    init (problemId) {
      // this.communityId = this.problemData.communityId
      // 获取小区列表
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.CommunityList = data.subAreaList
          } else {
          this.$toast.fail(data.msg);
          }
        })
        // 获取字典
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'problemType'
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.categoryList = data.dicts
          } else {
          this.$toast.fail(data.msg);
          }
        })
        // 获取详情
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: problemId
        })
      }).then(({data})=> {
          if (data.code == 0) {
            let Building = '请选择'
            let unit = '请选择'
            let house = '请选择'
            if(data.entityVO.houseFullName){
              let list = data.entityVO.houseFullName.split('-')
              Building = list[1]
              unit = list[2]
              house = list[3]
            }
          this.selectMsg = {
            category: data.entityVO.typeStr || '',
            Community: data.entityVO.subArea || '',
            position: data.entityVO.placeTypeStr || '',
            gridIdValue: data.entityVO.gridStr || '',
            driveStatusValue: data.entityVO.driveStatus ==0? '显示' : '不显示',
            Building: Building,
            unit: unit,
            house: house,
            roomUse: data.entityVO.roomUseStr || '请选择',
            room: data.entityVO.room || '',
            description: data.entityVO.content || '',
          }
          this.submitData = {
              building:data.entityVO.buildingId || '',
              content:data.entityVO.content || '',
              house:data.entityVO.houseId || '',
              location:data.entityVO.placeTypeStr == '室内楼栋'? '2' : (data.entityVO.placeTypeStr == '外部区域' ? '100' : '1'),
              orgId:this.$orgId,
              deleteFiles: [],
              fileMessages:data.entityVO.problemFiles || [],
              newFileMessages: [],
              id: problemId,
              subarea:data.entityVO.subAreaId || '',
              type:data.entityVO.type || '',
              unit:data.entityVO.unitId || '',
              roomUse: data.entityVO.roomUse || '',
              userId: this.$globalData.userInfo.userId || ''
          }
          // this.submitData.newFileMessages = this.submitData.fileMessages
            if (data.entityVO.problemFiles && data.entityVO.problemFiles.length != 0) {
              data.entityVO.problemFiles.forEach(item=> {
                console.log(item);
                let u;
                if (item.type == 1) {
                  u = getImageStream(item.filePath)

                  let url = {
                    uid: this.fileList.length,
                    id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                    status: 'done',//uploading上传中，done上传完成，error上传失败
                    fileType: item.fileType,
                    url: u,
                    realUrl: item.filePath.replace(/\\/g, "/")
                  }
                  this.fileList.push(url);
                }
              })
            }
          this.$toast.clear()
          } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
      }),
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
        method: 'post',
        params: this.$http.adornParams({
          subArea: this.submitData.subarea
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.BuildingList = data.buildings
          } else {
          this.$toast.fail(data.msg);
          }
        })
    },
    submit () {
      console.log(this.submitData)
      if (this.selectMsg.category === '请选择') {return this.$toast.fail('失败:请先选择事件类别');}
      // if (this.selectMsg.Community === '请选择') {return this.$toast.fail('失败:请先选择小区');}
      if (this.selectMsg.position === '请选择') {return this.$toast.fail('失败:请先选择位置');}
      if (this.submitData.location !== '100') {
        if (this.selectMsg.Community === '请选择') {return this.$toast.fail('失败:请先选择小区');}
      }
      if (this.selectMsg.gridIdValue === '请选择') {return this.$toast.fail('失败:请先选择网格');}
      if (this.selectMsg.driveStatusValue === '请选择') {return this.$toast.fail('失败:请先选择驾驶舱选项');}
      if (this.selectMsg.Building === '请选择'  && this.submitData.location == 2) {return this.$toast.fail('失败:请先选择楼幢');}
      if ( this.selectMsg.roomUse === '请选择' && this.submitData.location == 2) {return this.$toast.fail('失败:请先选择房屋用途');}
      if (this.selectMsg.description === '') {return this.$toast.fail('失败:请先输入事件描述');}
      this.submitData.content = this.selectMsg.description
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/save'),
        method: 'post',
        data : this.submitData
      }).then(({data})=> {
          if (data.code == 0) {
          this.$toast.success ('新增成功');
          this.$router.push('/issues')
          } else {
          this.$toast.fail(data.msg);
          }
        })
    },
    // 获取网格列表
    getGrids () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp//grid/info/listByUser`),
        method: 'get',
        params: this.$http.adornParams({
          'community': this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.gridList = data.grids
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 问题类型选择
    categoryConfirm (value) {
      if (value){
      this.selectMsg.category = value.label
      this.submitData.type = value.value
      }
      this.categoryshow = false
    },
    // 小区选择
    CommunityConfirm (value) {
      console.log(value);
      if (value) {
        this.selectMsg.Community = value.name
        this.submitData.subarea = value.id
        this.selectMsg.position = '请选择'
        this.selectMsg.Building = '请选择'
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectData.unitList = []
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
        this.selectData.BuildingList = []
        this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
        method: 'post',
        params: this.$http.adornParams({
          subArea: value.id
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.BuildingList = data.buildings
          } else {
          this.$toast.fail(data.msg);
          }
        })
      }
      this.CommunityShow = false
    },
    // 当前位置选择
    positionConfirm (value) {
      if (value) {
        this.selectMsg.position = value.label
        this.submitData.location = value.value
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
      }
        this.positionShow = false
    },
    // 楼栋选择
    BuildingConfirm (value) {
      if (value) {
      this.selectMsg.Building = value.label
      this.submitData.building = value.value
      this.selectMsg.unit = '请选择'
      this.submitData.unit = ''
      this.selectData.houseList = []
      this.selectMsg.house = '请选择'
      this.submitData.house = ''
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/unit/listByBuildingId'),
        method: 'post',
        params: this.$http.adornParams({
          buildingId: value.value
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.unitList = data.units
          } else {
          this.$toast.fail(data.msg);
          }
      })
      this.BuildingShow = false
    },
    // 单元选择
    unitConfirm (value) {
      if(value) {
      this.selectMsg.unit = value.label
      this.submitData.unit = value.value
      this.selectMsg.house = '请选择'
      this.submitData.house = ''
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/listByUpId'),
        method: 'post',
        params: this.$http.adornParams({
          upId: value.value,
          bOru: 2
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            console.log(data);
            this.selectData.houseList = data.houseOptions
          } else {
          this.$toast.fail(data.msg);
          }
      })
      this.unitShow = false
    },
    // 房屋选择
    houseConfirm (value) {
      if(value) {
      this.selectMsg.house = value.label
      this.submitData.house = value.value
      }
      this.houseShow = false
    },
    roomUseConfirm (value) {
      if(value) {
        this.selectMsg.roomUse = value.label
        this.submitData.roomUse = value.value
      }
      this.roomUseShow = false
    },
    gridConfirm (value) {
      if(value) {
        this.selectMsg.gridIdValue = value.label
        this.submitData.gridId = value.value
      }
      this.gridShow = false
    },
    statusConfirm (value) {
      // debugger
      if(value) {
        this.selectMsg.driveStatusValue = value.label
        this.submitData.driveStatus = value.value
      }
      this.statusShow = false
    },
    onCancel() {
    },
  },
  created () {
    let problemId = this.$route.query.id
    this.init(problemId)
    this.getGrids()
  }
}
</script>
<style>
  a {
  color: rgb(4, 50, 255);
}
.blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
.van-field__control {
  text-align: right !important;
}
</style>
<style lang="scss" scoped>
.info {
  padding-bottom: 150px;
}
  .van-divider{
    border-color: #000;
  }
  .van-field {
    padding: 0;
  }
</style>
